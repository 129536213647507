import type { SgwtHelpCenterHTMLElement } from '@sgmo/shared';
/* eslint-disable jsx-a11y/control-has-associated-label */
import type React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface PageNotFoundProps {
  intlIssueDescription?: string;
  intlGoBackLabel?: string;
  redirectTo?: string;
}

const XlErrorCodeSpan = styled.span.attrs({
  className: 'mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium',
})`
	font-size: 96px;
	overflow: 'hidden';
`;

const SmallErrorCodeSpan = styled.span.attrs({
  className: 'd-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium',
})`
	font-size: 56px;
	font-family: 'Inter';
`;

const Background = styled.div.attrs({ className: 'd-none d-lg-block' })`
	padding-top: 100%;
	background: #fff url(/404.svg) center no-repeat;
	background-size: 130%;
`;

export const PageNotFound: React.FC<PageNotFoundProps> = ({
  intlIssueDescription,
  intlGoBackLabel,
  redirectTo,
}: PageNotFoundProps) => {
  const showHelpCenter = () => {
    const widget = document.querySelector('sgwt-help-center') as SgwtHelpCenterHTMLElement;
    if (widget) {
      widget.form();
    }
  };

  return (
    <div className="page-404 pt-5 bg-white">
      <section className="section-2 mt-5 vh-100">
        <div className="container-fluid">
          <div className="container main-wrapper">
            <div className="row">
              <div className="col-lg-2 pt-5 pb-4 py-lg-0">
                <SmallErrorCodeSpan>404</SmallErrorCodeSpan>
                <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">
                  404
                </span>
                <XlErrorCodeSpan>404</XlErrorCodeSpan>
              </div>
              <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">
                    <FormattedMessage id="page-404.title" />
                  </h1>
                  <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">
                    <FormattedMessage id="page-404.title" />
                  </h1>

                  {/* Mobile */}
                  <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                    <FormattedMessage id={intlIssueDescription ?? 'page-404.description'} />
                  </p>
                  {/* Desktop */}
                  <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                    <FormattedMessage id={intlIssueDescription ?? 'page-404.description'} />
                  </p>

                  {/* XXL */}
                  <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                    <FormattedMessage id={intlIssueDescription ?? 'page-404.description'} />
                  </p>
                </div>
                <div className="mb-5 d-md-flex">
                  <NavLink className="btn btn-xl btn-primary" to={redirectTo ?? '/'}>
                    <FormattedMessage id={intlGoBackLabel ?? 'page-404.go-home'} />
                  </NavLink>
                  <button type="button" className="btn btn-xl btn-flat-secondary ml-1" onClick={showHelpCenter}>
                    <FormattedMessage id="page-404.contact-us" />
                  </button>
                </div>
              </div>
              <div className="col-4 px-0 position-relative">
                <Background />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
