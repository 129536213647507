import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SGWTConnectCore } from '@sgwt/connect-core';

import { getConfig } from '@/config/config';
import type { UserInfos } from '@/types/UserInfos';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api.endpoint,
  }),
  endpoints: (builder) => ({
    getUser: builder.query<
      UserInfos,
      {
        sgConnect: SGWTConnectCore | null;
      }
    >({
      query: ({ sgConnect }) => ({
        url: 'user/current',
        method: 'GET',
        headers: {
          Authorization: sgConnect?.getAuthorizationHeader() as string,
        },
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userApi;
