import { type ToastPusher, ToasterContext } from '@/context/ToasterContext';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Toast, ToastBody } from 'reactstrap';
import styled from 'styled-components';

import type { OnlyChildrenProps } from '@/types/custom';
import type { ToastMessage } from '@/types/toast';

interface TimestampedToastMessage extends ToastMessage {
  timestamp: number;
  isOpen: boolean;
  removeToast: () => void;
}

const ToasterContainer = styled.div`
	position: fixed;
	bottom: 0;
	z-index: 5000;
	width: 100vw;
`;

export let createToast: ToastPusher = (msg: ToastMessage) => undefined; // eslint-disable-line

export const ToasterProvider = ({ children }: OnlyChildrenProps) => {
  const [toastMessages, setToastMessages] = useState<TimestampedToastMessage[]>([]);

  const pushToast = useMemo(
    () => (toastMessage: ToastMessage) => {
      // many
      const removeToast = (toRemove: TimestampedToastMessage) => {
        setToastMessages((prevList) =>
          prevList
            .filter((toast) => toast.isOpen)
            .map((toast) => {
              if (toast?.timestamp === toRemove?.timestamp) {
                setTimeout(removeToast, 4000);
                return { ...toast, isOpen: false };
              }
              return toast;
            }),
        );
      };

      const tToastMessage = {
        ...toastMessage,
        timestamp: Date.now(),
        isOpen: true,
        removeToast: () => removeToast(tToastMessage),
      };

      const newList = [...toastMessages, tToastMessage];
      setToastMessages(newList);

      setTimeout(() => removeToast(tToastMessage), 4000);

      return () => removeToast(tToastMessage);
    },
    [toastMessages, setToastMessages],
  );

  useEffect(() => {
    createToast = pushToast;

    return () => {
      createToast = () => undefined;
    };
  }, [pushToast]);

  return (
    <ToasterContext.Provider value={{ pushToastMessage: pushToast }}>
      {children}
      <ToasterContainer>
        {toastMessages.map((toast) =>
          toast.type === 'alert' ? (
            <Alert
              key={toast.timestamp}
              color={toast.level}
              isOpen={toast.isOpen}
              style={{ margin: 0, marginTop: 20 }}
              icon={toast.icon}
              data-testid="AlertUnit"
            >
              <i className="icon mr-2" style={{ color: 'white' }}>
                {toast.icon}
              </i>
              {toast.text}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={toast.removeToast}
              >
                <i className="icon" style={{ color: 'white' }}>
                  close
                </i>
              </button>
            </Alert>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }} key={toast.timestamp}>
              <Toast
                animation="true"
                key={toast.timestamp}
                isOpen={toast.isOpen}
                className="Toast"
                bg={toast.level}
                data-testid="ToastUnit"
                style={{
                  backgroundColor: `var(--${toast.level})`,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  border: 0,
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <ToastBody
                  style={{
                    backgroundColor: `var(--${toast.level})`,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1rem',
                    width: '100%',
                    height: '100%',
                    border: 0,
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                  className="text-white"
                >
                  {toast.text}
                </ToastBody>
              </Toast>
            </div>
          ),
        )}
      </ToasterContainer>
    </ToasterContext.Provider>
  );
};

/*
setTimeout(() => {
			setToastMessages((prevList) =>
				prevList
				.filter((toast) => toast.isOpen) // toast deletion in two steps so it renders nicely the fade out and it cleans the list
				.map((toast) => {
					if (
						!toast.timestamp ||
						toast.timestamp === tToastMessage.timestamp
						) {
						return { ...toast, isOpen: false };
				}
				return toast;
			}),
				);
		}, 10000);
*/
