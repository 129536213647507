import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type Rights = {
  canRead: boolean;
  canWrite: boolean;
  canUseSmartDX: boolean;
};

export type UIState = {
  query: {
    isredraft: boolean;
    isoriginal: boolean;
    isbomanager: boolean;
    SkipReviewCase: boolean;
    isreadonly: boolean;
    noFlag: boolean;
  };
  rights: Rights;
  modals: Record<string, boolean>;
  origin: {
    isXone: boolean;
    isOctane: boolean;
    isKB: boolean;
  };
  timedout: boolean;
  loading: Record<string, boolean | undefined>;
  consumed: string[];
};

const initialState: UIState = {
  query: {
    isredraft: false,
    isoriginal: false,
    isbomanager: false,
    SkipReviewCase: false,
    isreadonly: false,
    noFlag: false,
  },
  rights: {
    canRead: true,
    canWrite: false,
    canUseSmartDX: false,
  },
  modals: {
    LoadURL: false,
    Attachment: false,
    Compare: false,
    SaveNStore: false,
    SaveNSend: false,
    Chasing: false,
    CancelChasing: false,
    Concurency: false,
    SaveOctane: false,
    TimeOut: false,
  },
  origin: {
    isXone: false,
    isOctane: false,
    isKB: false,
  },
  timedout: false,
  loading: {},
  consumed: [],
};

export const UISlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<UIState['query']>) => {
      state.query = action.payload;
    },
    setRights: (state, action: PayloadAction<Rights>) => {
      state.rights = action.payload;
    },
    modalOpenState: (state, action: PayloadAction<{ openState: boolean; modalId: string }>) => {
      state.modals[action.payload.modalId] = action.payload.openState;
    },
    setOrigin: (state, action: PayloadAction<Partial<UIState['origin']>>) => {
      if (action.payload.isOctane !== undefined) {
        state.origin.isOctane = action.payload.isOctane;
      }

      if (action.payload.isXone !== undefined) {
        state.origin.isXone = action.payload.isXone;
      }

      if (action.payload.isKB !== undefined) {
        state.origin.isKB = action.payload.isKB;
      }
    },
    setTimedout: (state, action: PayloadAction<boolean>) => {
      state.timedout = action.payload;
    },

    setLoading: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = true;
    },
    setFinishedLoading: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = false;
    },
    consumed: (state, { payload }: PayloadAction<string>) => {
      state.consumed.push(payload);
    },
  },
  selectors: {
    selectRights: (state): Rights => state.rights,
    selectQueryParams: (state) => state.query,
    selectOrigin: (state) => state.origin,
    selectFromXOne: (state) => state.origin.isXone || state.origin.isKB,
    selectTimedout: (state) => state.timedout,
    selectModalsList: (state) => state.modals,
    selectLoadersList: (state) => state.loading,
    selectConsumedList: (state) => state.consumed,
  },
});

export const { setQuery, setRights, modalOpenState, setOrigin, setTimedout, setLoading, setFinishedLoading, consumed } =
  UISlice.actions;

export const {
  selectRights,
  selectQueryParams,
  selectOrigin,
  selectFromXOne,
  selectTimedout,
  selectModalsList,
  selectLoadersList,
  selectConsumedList,
} = UISlice.selectors;

export const selectModal = (modalId: string) => createSelector(selectModalsList, (modalsList) => modalsList[modalId]);

export const selectLoading = (loader: string) =>
  createSelector(selectLoadersList, (loadersList) => loadersList[loader]);

export const selectIsConsumed = (id: string) =>
  createSelector(selectConsumedList, (consumedList) => consumedList.includes(id));
