import { HelpCenter } from '@/components/sgwt/HelpCenter';
import { MiniFooter } from '@/components/sgwt/MiniFooter';
import React from 'react';

export function Footer() {
  return (
    <footer
      style={{
        bottom: 0,
        width: '100vw',
        backgroundColor: 'var(--bg-lvl1)',
        paddingRight: 5,
      }}
    >
      <HelpCenter />
      <MiniFooter />
    </footer>
  );
}
