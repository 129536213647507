import type { GlobalContext } from '@/types/global-context';
import React from 'react';

let currentContext: GlobalContext;

export const setCurrentContext = (context: GlobalContext) => {
  currentContext = context;
  return context;
};
export const getCurrentContext = (): Readonly<GlobalContext> => currentContext;

interface UserContextProps {
  globalContext: GlobalContext;
}

export const UserContext = React.createContext<UserContextProps>({
  globalContext: {
    clientScopeCode: '',
    setClientScope: () => undefined,
    permissions: null,
    sgConnect: null,
    appConfig: null,
    user: null,
  },
});
