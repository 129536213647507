import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SGWTConnectCore } from '@sgwt/connect-core';

import { type FrontConfigType, getConfig } from '@/config/config';
import { getCurrentContext } from '@/context/UserContext';
import type { Permissions } from '@/types/permissions';

export const presentationApi = createApi({
  reducerPath: 'presentationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api.endpoint,
    prepareHeaders: (headers) => {
      const authHeader = getCurrentContext().sgConnect?.getAuthorizationHeader();

      headers.set('Authorization', authHeader as string);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPermissions: builder.query<Permissions, void>({
      query: () => ({
        url: 'permissions',
        method: 'POST',
        // headers: {
        //   'X-Fake-Authentication': 'noSmarDx.permission@sgcib.com',
        // },
        body: {
          accessNames: [
            'ConfirmationDocumentTypes',
            'Commands',
            'CanWriteSmartDXDoc',
          ],
        },
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getFrontConfig: builder.query<FrontConfigType, void>({
      query: () => ({
        url: 'config',
        method: 'GET',
      }),
    }),

    getScope: builder.query<
      Permissions,
      {
        sgConnect: SGWTConnectCore | null;
      }
    >({
      query: () => ({
        url: 'permissions',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const { useGetPermissionsQuery, useGetFrontConfigQuery } =
  presentationApi;
