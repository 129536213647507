import type { ChasingInfo } from '@/types/fileInfos';
/* eslint-disable no-param-reassign */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type FileInfo = {
  documentId?: string;
  tradeReference: string;
  documentType: string;
  assetClass: string;
  lang?: string;
  fileName?: string;
  fromPc?: boolean;
  isReadOnly?: boolean;
};

export interface DocumentState {
  file: FileInfo;
  chasing: ChasingInfo & { attachments: { key: string; value: ArrayBuffer }[] };
}

const initialState: DocumentState = {
  file: {
    documentId: undefined,
    tradeReference: '',
    documentType: '',
    assetClass: '',
    lang: undefined,
    fileName: undefined,
    fromPc: undefined,
    isReadOnly: false,
  },
  chasing: {
    documentType: undefined,
    codent: undefined,
    codtrs: undefined,
    chasing: undefined,
    chasingDate: undefined,
    client: undefined,
    attachments: [],
  },
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setFile: (state: DocumentState, action: PayloadAction<FileInfo>) => {
      state.file.documentId = action.payload.documentId;
      state.file.tradeReference = action.payload.tradeReference;
      state.file.documentType = action.payload.documentType;
      state.file.assetClass = action.payload.assetClass;
      state.file.lang = action.payload.lang;
      state.file.fileName = action.payload.fileName;
      state.file.fromPc = action.payload.fromPc;
      state.file.isReadOnly = action.payload.isReadOnly;
    },
    setChasing: (state: DocumentState, action: PayloadAction<ChasingInfo>) => {
      state.chasing.documentType = action.payload.documentType;
      state.chasing.codent = action.payload.codent;
      state.chasing.codtrs = action.payload.codtrs;
      state.chasing.chasing = action.payload.chasing;
      state.chasing.chasingDate = action.payload.chasingDate;
      state.chasing.client = action.payload.client;
    },
    setFileName: (state: DocumentState, action: PayloadAction<string | undefined>) => {
      state.file.fileName = action.payload;
    },
    addChasingAttachments: (state: DocumentState, { payload }: PayloadAction<{ key: string; value: ArrayBuffer }>) => {
      state.chasing.attachments.push(payload);
    },
    removeChasingAttachments: (state: DocumentState, { payload }: PayloadAction<string>) => {
      const startIndex = state.chasing.attachments.findIndex((attachment) => attachment.key === payload);
      if (startIndex !== -1) {
        state.chasing.attachments.splice(startIndex, 1);
      }
    },
  },
  selectors: {
    selectDocument: (state): FileInfo => state.file,
    selectChasing: (state): ChasingInfo => state.chasing,
    selectChasingAttachment: (state) => state.chasing.attachments,
  }
});

export const { setFile, setChasing, addChasingAttachments, removeChasingAttachments } = documentSlice.actions;
export const { selectDocument, selectChasing, selectChasingAttachment } = documentSlice.selectors;
