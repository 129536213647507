import React from 'react';

import type { ToastMessage } from '../types/toast';

export type { ToastMessage };

export type ToastPusher = (message: ToastMessage) => void;

interface ToasterProps {
  pushToastMessage: ToastPusher;
}

export const ToasterContext = React.createContext<ToasterProps>({
  pushToastMessage: () => undefined,
});
