import { rapidToast } from '@/helpers/powerFullToast';
import { type Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';

export const toastMiddleware: Middleware = () => (next) => (action: any) => {
  if (isRejectedWithValue(action) && action.meta.arg.silent !== false && action.meta.arg.endpointName !== 'getUser') {
    const endpoint = action.meta.arg.endpointName;
    const { status } = action.payload;
    let type = action.type.slice(0, action.type.indexOf('Api'));
    type = type.charAt(0).toUpperCase() + type.slice(1);
    if (type === 'Document') {
      rapidToast(type)(endpoint)(status);
    }
  }
  if (action.meta?.requestStatus === 'fulfilled') {
    const endpoint = action.meta.arg.endpointName;
    let type = action.type.slice(0, action.type.indexOf('Api'));
    type = type.charAt(0).toUpperCase() + type.slice(1);
    if (type === 'Document' && endpoint !== 'getDocumentCompare' && !action.meta?.arg?.originalArgs?.silent) {
      if (endpoint === 'putSettlement') {
        rapidToast(type)(endpoint)(200, [] as unknown as Record<string, ReactNode>, {
          level: 'success',
          type: 'alert',
        });
      } else {
        rapidToast(type)(endpoint)(200);
      }
    }
  }

  return next(action);
};
