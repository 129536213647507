import '@/components/layout/layout.css';

import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { Outlet } from 'react-router-dom';

export function Layout() {
  return (
    <div className="layout">
      <Header />

      <Outlet />

      <Footer />
    </div>
  );
}
