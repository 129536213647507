import { getConfig } from '@/config/config';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { LogBuilder } from '@sgme/logger';

let logBuilder = LogBuilder();

export const isNode = typeof process === 'object' && !!process.versions && !!process.versions.node;

logBuilder = logBuilder
  .enableServerLogs({
    storeName: 'main',
    url: `${getConfig().api.endpoint}log`,
    prefix: '[TradeDoc]',
  })
  .setAuthTokenFactory(() => {
    if (sgwtConnect.isAuthorized()) {
      const authToken = sgwtConnect.getAuthorizationHeader();
      if (authToken) {
        return authToken;
      }
    }
    throw new Error('No authorization header or token. Disconnected ?!');
  });

export const logger = logBuilder.build();
