import type { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export type SmartDxType = {
  scriptSrc: string;
  clourdUrl: string;
  authUrl: string;
  theme: string;
  url: string;
};

export type FrontConfigType = {
  smartDxData: SmartDxType;
};

export interface AppConfig {
  sgwt_env: 'homologation' | 'production';
  sgconnect: ISGWTConnectConfiguration;
  matomo: {
    url: string;
    id: number;
  };
  api: {
    endpoint: string;
  };
  publicBaseUrl: string;
}

export function getConfig(): AppConfig {
  return window.sgmeConfiguration;
}
