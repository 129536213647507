import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { SmartCommsTypes, SmartEditor } from '@/types/SmartCommsApi';

export type EditorState = {
  editor?: SmartEditor;
  currentConfig?: SmartCommsTypes['EditConfig'];
  currentContent?: string;
};

const initialState: EditorState = {};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditor: (state: EditorState, action: PayloadAction<SmartEditor | undefined>) => {
      state.editor = action.payload;
    },
    setCurrentConfig: (state: EditorState, action: PayloadAction<SmartCommsTypes['EditConfig']>) => {
      state.currentConfig = action.payload;
      state.editor?.setEditMode(action.payload);
    },
    setCurrentSgDocContent: (state: EditorState, action: PayloadAction<string>) => {
      state.currentConfig = { mode: 'readOnly' };
      state.currentContent = action.payload;
      state.editor?.loadString(action.payload, { openInPreview: false, trackChanges: false });
    },
    setCompareState: (state: EditorState, action: PayloadAction<{ contentSrc: string; contentDest: string }>) => {
      state.currentConfig = { mode: 'compareMode' };
      state.editor?.setEditMode({ mode: 'compareMode' });
      state.editor?.compareDraftTML(action.payload.contentSrc, action.payload.contentDest, {
        useDualColourDifferences: false,
      });
    },
  },
  selectors: {
    selectEditorState: (state) => state,
    selectEditor: (state) => state.editor,
    selectCurrentMode: (state) => state.currentConfig?.mode,
  }
});

export const { setEditor, setCurrentConfig, setCompareState, setCurrentSgDocContent } = editorSlice.actions;
export const { selectEditorState, selectEditor, selectCurrentMode } = editorSlice.selectors;
