import { useCallback } from 'react';
import './App.css';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import { useDispatch, useSelector } from 'react-redux';

import { IntlProvider } from '@/context/IntlContext';
import { createBrowserRouter, type RouteObject, RouterProvider } from 'react-router-dom';
import { ToasterProvider } from '@/components/common/ToasterProvider';

import { setCurrentContext, UserContext } from '@/context/UserContext';

import type { GlobalContext } from '@/types/global-context';
import { getConfig } from '@/config/config';
import { setCurrentConfig } from '@/store/editor.slice';
import { selectRights, setOrigin, setRights } from '@/store/ui.slice';
import { Sgb4Spinner } from '@/components/common/Sgb4Spinner';
import { Layout } from './layout/Layout';
import { PageNotFound } from '@/components/routes/PageNotFound';

export function App({ sgConnect }: { sgConnect: SGWTConnectCore }) {
  const globalContext: GlobalContext = {
    clientScopeCode: 'SCP0',
    setClientScope: () => undefined,
    permissions: null,
    sgConnect,
    user: null,
    appConfig: getConfig(),
  };

  setCurrentContext(globalContext);

  const dispatch = useDispatch();

  const setOriginState = useCallback(
    (origin: 'xone' | 'octane' | 'kb' = 'xone') => {

      dispatch(
        setOrigin({
          isXone: origin === 'xone',
          isOctane: origin === 'octane',
          isKB: origin === 'kb',
        }),
      );
    },
    [dispatch],
  );

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          async lazy() {
            const { LandingPage } = await import('./routes/Landing');

            return { Component: LandingPage };
          },
        },
        // Description: takes the user to a page to open a document with edit rights (as opposed to the one below)
        {
          path: 'review',
          lazy: async () => {
            setOriginState();
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: takes the user to a page to open a document but with no edit rights (as opposed to the one above)
        {
          path: 'preview',
          lazy: async () => {
            setOriginState();
            const { PreviewXoneWrapper } = await import('./routes/Editor/Xone/PreviewXone/PreviewXoneWrapper');

            return { Component: PreviewXoneWrapper };
          },
        },
        // Description: review page for users coming from X-One, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'xone/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState();
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: preview page for users coming from X-One, does not allow users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/:docType/:tradeRef/:docId',
          lazy: async () => {
            setOriginState();
            const { PreviewXoneWrapper } = await import('./routes/Editor/Xone/PreviewXone/PreviewXoneWrapper');

            return { Component: PreviewXoneWrapper };
          },
        },
        // Description: review page for users coming from Enterprise, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'ent/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState();
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :codent: enterprise code (i.e. SG)
        //     :codtrs: document id (i.e. 0123456789)
        //     :chasing: chasing type (i.e. Reminder, Out_of_Target...)
        //     :chasingDate: chasing date (i.e. 21_02_24_12_00_00_AM)
        //     :client: client to be chased (i.e. TOTAL)
        {
          path: 'octane/CHASING/:codent/:codtrs/:chasing/:chasingDate/:client',
          lazy: async () => {
            setOriginState('octane');
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :docId: document id (i.e. 0123456789)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        {
          path: 'octane/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState('octane');
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: preview page for documents from SG Docs
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/Sgdoc/:sgDocsId',
          lazy: async () => {
            dispatch(setCurrentConfig({ mode: 'readOnly' }));
            setOriginState();
            const { SgDocPreviewWrapper } = await import('./routes/Editor/SgDocs/SgDocPreview/SgDocPreviewWrapper');

            return { Component: SgDocPreviewWrapper };
          },
        },
        // Description: dedicated page to compare two SG Docs documents
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)*/}
        //     :tradeRef: trade reference (i.e. ENU-1234567)*/}
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        {
          path: 'showDiff/:sgDocsId/:tradeRef',
          lazy: async () => {
            const { SgDocsComparator } = await import('./routes/Editor/SgDocs/SgDocsComparator');

            return { Component: SgDocsComparator };
          },
        },
        // Description: dedicated page for KB users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'kb/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState('kb');
            const { ReviewPageWrapper } = await import('./routes/Editor/common/ReviewPageWrapper');

            return { Component: ReviewPageWrapper };
          },
        },
        // Description: dedicated page for KB users to preview a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/kb/:docType/:tradeRef/:docId',
          lazy: async () => {
            setOriginState('kb');

            const { canRead, canWrite, canUseSmartDX } = useSelector(selectRights);
            if (canWrite) {
              dispatch(setRights({ canRead, canWrite: false, canUseSmartDX }));
              dispatch(setCurrentConfig({ mode: 'readOnly' }));
            }

            const { PreviewXoneWrapper } = await import('./routes/Editor/Xone/PreviewXone/PreviewXoneWrapper');

            return { Component: PreviewXoneWrapper };
          },
        },
      ],
      errorElement: <PageNotFound />
    },
  ];

  const router = createBrowserRouter(routes, { basename: getConfig().publicBaseUrl });

  return (
    <div id="main" className="vh-100">
      <IntlProvider>
        <UserContext.Provider value={{ globalContext }}>
          <ToasterProvider>
            <RouterProvider
              router={router}
              fallbackElement={
                <div className="m-5 d-flex justify-content-center">
                  <Sgb4Spinner />
                </div>
              }
            />
          </ToasterProvider>
        </UserContext.Provider>
      </IntlProvider>
    </div>
  );
}
